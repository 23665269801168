import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const ResultsSubjectLevel = ({ classId, subjectName }) => {
    const { user } = useContext(AuthContext);
    const [results, setResults] = useState([]);
    const [students, setStudents] = useState([]);
    const [term, setTerm] = useState('');
    const [year, setYear] = useState('');
    const [form, setForm] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [newPaperMarks, setNewPaperMarks] = useState([{ PaperName: '', Mark: '', Term: '', Year: '' }]);
    const [comment, setComment] = useState('');
    const [resultID, setResultID] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [resultToDelete, setResultToDelete] = useState(null);
    const [hasSearched, setHasSearched] = useState(false);
    const [subjectNameFromApi, setSubjectNameFromApi] = useState('');
    const [gradelevelclass, setGradelevelclass] = useState('');
    const [selectedResult, setSelectedResult] = useState(null);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    useEffect(() => {
        if (classId) {
            fetchStudents();
            fetchClassDetails();
        }
    }, [classId, term, year, form, students]);

    const fetchResults = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/addsubjectresult/subjectresults`, {
                ClassID: classId,
                TermID: term,
                Year: year,
            });
            console.log('API Response:', response.data);
            if (response.data.success) {
                const fetchedResults = response.data.data || [];
                console.log('Processed Results:', fetchedResults);
                setResults(fetchedResults);
            } else {
                console.error('API returned success: false');
                setResults([]);
            }
            setHasSearched(true);
        } catch (error) {
            console.error('Error fetching results:', error);
        }
    };


    const handleFetchResults = () => {
        fetchResults();
    };

    const fetchClassDetails = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/subjectlevelclasses/get-class/${classId}`);
            const { gradelevelclass, Subject } = response.data;
            setSubjectNameFromApi(Subject);
            setGradelevelclass(gradelevelclass); // Save the subject name in the state
        } catch (error) {
            console.error('Error fetching class details:', error);
        }
    };

    const handleDeleteResult = async () => {
        setIsLoadingDelete(true);
        try {
            // Send the delete request
            await axios.delete(`${BASE_URL}/addsubjectresult/${resultToDelete}`);
    
            // Close the delete modal
            setDeleteModalVisible(false);
    
            // Fetch updated results
            await fetchResults();
    
            alert('Result deleted successfully');
        } catch (error) {
            console.error('Error deleting result:', error);
            alert('Error deleting result');
        } finally {
            setIsLoadingDelete(false);
        }
    };
    
    const openViewModal = (result) => {
        setSelectedResult(result); // Store the selected result
        setIsViewModalVisible(true); // Open the modal
    };

    const fetchStudents = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/subjectlevelclasses-students/get-students-class/${classId}`);
            setStudents(response.data.students);
        } catch (error) {
            console.error('Error fetching students:', error);
        }
    };

    const handleAddResult = async () => {
        console.log('Add Result button clicked');
        console.log('Selected Student:', selectedStudent);
        console.log('Term:', term);
        console.log('Year:', year);
        console.log('Paper Marks:', newPaperMarks);
    
        // Validate required fields
        if (!selectedStudent || !term || !year) {
            console.log('Missing required fields:', {
                student: !selectedStudent,
                term: !term,
                year: !year,
            });
            setErrorMessage('Please select a student, term, and year');
            setErrorModalVisible(true);
            return;
        }
    
        const resultData = {
            subjectResultData: {
                StudentRegNumber: selectedStudent.RegNumber,
                SubjectName: subjectNameFromApi,
                TermID: term,
                Year: year,
                ClassID: gradelevelclass,
                Comment: comment,
                SubjectClassID: classId,
            },
            paperMarks: newPaperMarks,
        };
    
        console.log('Sending result data:', resultData);
    
        try {
            console.log('Making API request to:', `${BASE_URL}/addsubjectresult`);
            const response = await axios.post(`${BASE_URL}/addsubjectresult`, resultData);
            console.log('API Response:', response);
    
            if (response.status === 200 || response.status === 201) {
                console.log('Result added successfully. Fetching updated results...');
                
                setSuccessModalVisible(true);
                setIsModalVisible(false);
                resetForm();
    
                // Fetch updated results for the current term and year
                await fetchResults();
    
                // Hide success modal after 3 seconds
                setTimeout(() => {
                    setSuccessModalVisible(false);
                }, 3000);
            } else {
                console.error('Failed to add result. API returned:', response.status);
                alert('Failed to add result. Please try again.');
            }
        } catch (error) {
            console.error('Error adding result:', error);
            setErrorMessage(error.response?.data?.message || 'Error adding result');
            setErrorModalVisible(true);
        }
    };
    

    const handleEditResult = async () => {
        try {
            // Update the result
            await axios.post(`${BASE_URL}/addsubjectresult/updateSubjectResult`, {
                ResultID: resultID,
                PaperMarks: newPaperMarks,
                Comment: comment,
            });
    
            alert('Result updated successfully');
    
            // Close the modal and reset the form
            setIsModalVisible(false);
            resetForm();
    
            // Fetch updated results with the selected term and year
            await fetchResults();
    
        } catch (error) {
            console.error('Error updating result:', error);
            alert('Error updating result');
        }
    };
    
    const handleRemovePaperMark = (index) => {
        const updatedMarks = [...newPaperMarks];
        updatedMarks.splice(index, 1);
        setNewPaperMarks(updatedMarks);
    };

    const handleAddPaperMark = () => {
        setNewPaperMarks([...newPaperMarks, { PaperName: '', Mark: '', Term: '', Year: '' }]);
    };

    const openAddModal = () => {
        setIsEditMode(false);
        setIsModalVisible(true); // This is the key line to open the modal
        resetForm();
    };

    const openEditModal = (result) => {
        setIsEditMode(true);
        setSelectedStudent(result);
        setNewPaperMarks(result.PaperMarks);
        setComment(result.Comment);
        setResultID(result.ResultID);
        setTerm(result.TermID);
        setYear(result.Year);
        setIsModalVisible(true);
    };

    const resetForm = () => {
        setNewPaperMarks([{ PaperName: '', Mark: '', Term: '', Year: '' }]);
        setComment('');
        setSelectedStudent(null);
        setTerm('');
        setYear('');
    };

    const renderPaperMarks = (paperMarks) => {
        return (
            <ul>
                {paperMarks.map((paper, index) => (
                    <li key={index}>{paper.PaperName}: {paper.Mark}</li>
                ))}
            </ul>
        );
    };

    return (
        <div className="p-6 bg-white">
            <div className="flex flex-wrap items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-4">
                <input
                    type="text"
                    placeholder="Term"
                    className="border p-2 rounded-md w-full sm:w-1/4"
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Year"
                    className="border p-2 rounded-md w-full sm:w-1/4"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                />
                <button
                    className="bg-[#BB7B00] text-white px-3 py-2 rounded-md w-full sm:w-auto"
                    onClick={handleFetchResults}
                >
                    Fetch Results
                </button>
                <button
                    className="bg-green-500 text-white px-3 py-2 rounded-md w-full sm:w-auto"
                    onClick={openAddModal}
                >
                    Add Result
                </button>
            </div>

            <div className="overflow-x-auto mt-6">
                <div className="min-w-full shadow-sm rounded-lg overflow-hidden">
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-[#11024D]">
                                <tr>
                                    <th scope="col" className="px-6 py-4 text-left text-sm font-semibold text-white tracking-wider whitespace-nowrap">
                                        Reg Number
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-sm font-semibold text-white tracking-wider whitespace-nowrap">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-sm font-semibold text-white tracking-wider whitespace-nowrap">
                                        Surname
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-sm font-semibold text-white tracking-wider whitespace-nowrap">
                                        Mark
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-sm font-semibold text-white tracking-wider whitespace-nowrap">
                                        Grade
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left text-sm font-semibold text-white tracking-wider whitespace-nowrap">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {Array.isArray(results) && results.length > 0 ? (
                                    results.map((result, index) => (
                                        <tr
                                            key={result.ResultID}
                                            className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                                        >
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {result.StudentRegNumber}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {result.Name}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {result.Surname}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {result.AverageMark}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {result.Grade}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm space-x-2">
                                                <button
                                                    className="bg-blue-500 text-white px-3 py-1.5 rounded-md hover:bg-blue-600 transition-colors"
                                                    onClick={() => openEditModal(result)}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    className="bg-red-500 text-white px-3 py-1.5 rounded-md hover:bg-red-600 transition-colors"
                                                    onClick={() => {
                                                        setDeleteModalVisible(true);
                                                        setResultToDelete(result.ResultID);
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                                <button
                                                    className="bg-green-500 text-white px-3 py-1.5 rounded-md hover:bg-green-600 transition-colors"
                                                    onClick={() => openViewModal(result)}
                                                >
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="px-6 py-8 text-center text-gray-500 text-lg">
                                            <div className="flex flex-col items-center justify-center space-y-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 20a8 8 0 100-16 8 8 0 000 16z" />
                                                </svg>
                                                <p className="font-medium">No results found</p>
                                                <p className="text-sm text-gray-400">Try selecting a different term or year</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Add/Edit Modal */}
            {isModalVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 sm:p-6 md:p-8">
                    <div className="bg-white p-8 rounded-lg w-full max-w-2xl sm:max-w-lg md:max-w-xl lg:max-w-2xl shadow-lg max-h-[90vh] overflow-y-auto">
                        <h3 className="text-2xl font-bold mb-6 text-center text-[#11024D]">
                            {isEditMode ? 'Edit Result' : 'Add Result'}
                        </h3>

                        {/* Term and Year Selectors */}
                        <div className="flex flex-wrap md:flex-nowrap mb-6">
                            <div className="w-full md:w-1/2 mb-4 md:mb-0 md:mr-4">
                                <label className="block text-lg mb-2 font-semibold text-gray-700">Select Term</label>
                                <select
                                    className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                    value={term}
                                    onChange={(e) => setTerm(e.target.value)}
                                >
                                    <option value="">Select Term</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>
                            <div className="w-full md:w-1/2">
                                <label className="block text-lg mb-2 font-semibold text-gray-700">Select Year</label>
                                <select
                                    className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                >
                                    <option value="">Select Year</option>
                                    {[...Array(21).keys()].map((i) => (
                                        <option key={i} value={2020 + i}>
                                            {2020 + i}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Student Selector */}
                        {!isEditMode && (
                            <div className="mb-4">
                                <label className="block text-lg mb-2 font-semibold text-gray-700">Select Student</label>
                                <select
                                    className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                    value={selectedStudent?.RegNumber || ''}
                                    onChange={(e) => {
                                        const student = students.find((s) => s.RegNumber === e.target.value);
                                        setSelectedStudent(student);
                                    }}
                                >
                                    <option value="">Select Student</option>
                                    {students.map((student) => (
                                        <option key={student.RegNumber} value={student.RegNumber}>
                                            {student.Name} {student.Surname}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {/* Comment Input */}
                        <div className="mb-4">
                            <label className="block text-lg mb-2 font-semibold text-gray-700">Comment</label>
                            <input
                                type="text"
                                className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                placeholder="Enter Comment"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </div>

                        {/* Paper Marks */}
                        {newPaperMarks.map((paper, index) => (
                            <div key={index} className="flex items-center mb-4">
                                <div className="w-full md:w-1/3 mb-2 md:mb-0 md:mr-2">
                                    <label className="block text-lg mb-2 font-semibold text-gray-700">Paper Name</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                        placeholder="Paper Name"
                                        value={paper.PaperName}
                                        onChange={(e) => {
                                            const newMarks = [...newPaperMarks];
                                            newMarks[index].PaperName = e.target.value;
                                            setNewPaperMarks(newMarks);
                                        }}
                                    />
                                </div>

                                <div className="w-full md:w-1/3 mb-2 md:mb-0 md:mr-2">
                                    <label className="block text-lg mb-2 font-semibold text-gray-700">Mark</label>
                                    <input
                                        type="number"
                                        className="border border-gray-300 p-3 rounded-md w-full text-lg focus:outline-none focus:ring-2 focus:ring-[#11024D] transition"
                                        placeholder="Mark"
                                        value={paper.Mark}
                                        onChange={(e) => {
                                            const newMarks = [...newPaperMarks];
                                            newMarks[index].Mark = e.target.value;
                                            setNewPaperMarks(newMarks);
                                        }}
                                    />
                                </div>

                                <button
                                    className="bg-red-500 text-white flex items-center mt-8 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-red-700 transition"
                                    onClick={() => handleRemovePaperMark(index)}
                                >
                                    Remove
                                </button>
                            </div>
                        ))}

                        <button
                            className="w-full bg-gray-200 text-gray-700 text-lg px-4 py-3 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 transition mb-6"
                            onClick={handleAddPaperMark}
                        >
                            Add Paper Mark
                        </button>

                        {/* Action Buttons */}
                        <div className="flex justify-between items-center">
                            <button
                                className="bg-blue-600 text-white text-lg px-6 py-3 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
                                onClick={isEditMode ? handleEditResult : handleAddResult}
                            >
                                {isEditMode ? 'Save Changes' : 'Save'}
                            </button>
                            <button
                                className="bg-gray-300 text-black text-lg px-6 py-3 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 transition"
                                onClick={() => setIsModalVisible(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isViewModalVisible && selectedResult && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 sm:p-6 md:p-8 z-50">
                    <div className="bg-white p-8 rounded-lg w-full max-w-2xl sm:max-w-lg md:max-w-xl lg:max-w-2xl shadow-lg">
                        {/* Header Section */}
                        <div className="mb-6 text-center">
                            <h3 className="text-3xl font-extrabold text-[#11024D]">
                                {selectedResult.SubjectName}
                            </h3>
                            <p className="text-lg text-gray-700 mt-2">
                                {selectedResult.Name} {selectedResult.Surname} ({selectedResult.StudentRegNumber})
                            </p>
                        </div>

                        {/* Content Section */}
                        <div className="space-y-4 text-gray-800">
                            <p className="flex justify-between border-b pb-2">
                                <span className="font-semibold">Total Mark:</span>
                                <span>{selectedResult.TotalMark}</span>
                            </p>
                            <p className="flex justify-between border-b pb-2">
                                <span className="font-semibold">Average Mark:</span>
                                <span>{selectedResult.AverageMark}</span>
                            </p>
                            <p className="flex justify-between border-b pb-2">
                                <span className="font-semibold">Grade:</span>
                                <span>{selectedResult.Grade}</span>
                            </p>
                        </div>

                        {/* Paper Marks Section */}
                        <div className="mt-6">
                            <h4 className="text-xl font-bold text-[#11024D] mb-4">Paper Marks</h4>
                            <div className="space-y-2">
                                {selectedResult.PaperMarks.map((paper, index) => (
                                    <div
                                        key={index}
                                        className="flex justify-between px-4 py-2 bg-gray-100 rounded-lg shadow-sm"
                                    >
                                        <span className="font-medium">{paper.PaperName}</span>
                                        <span>{paper.Mark}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Footer Section */}
                        <div className="mt-8 flex justify-center">
                            <button
                                className="bg-[#11024D] text-white px-6 py-3 rounded-md hover:bg-[#260d6b] transition-colors"
                                onClick={() => setIsViewModalVisible(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {successModalVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white p-6 rounded-md text-center">
                        <h3 className="text-lg font-bold">Result added successfully!</h3>
                    </div>
                </div>
            )}

            {/* Error Modal */}
            {errorModalVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
                        <div className="text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mb-4">
                                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            </div>
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Error</h3>
                            <p className="text-sm text-gray-500 mb-4">{errorMessage}</p>
                            <button
                                onClick={() => setErrorModalVisible(false)}
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {/* Delete Confirmation Modal */}
            {deleteModalVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg w-full max-w-md">
                        <h3 className="text-lg font-bold mb-4">Confirm Delete</h3>
                        <p>Are you sure you want to delete this result?</p>
                        <div className="flex justify-between mt-4">
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-md"
                                onClick={handleDeleteResult}
                            >
                                Yes
                            </button>
                            <button
                                className="bg-gray-300 text-black px-4 py-2 rounded-md"
                                onClick={() => setDeleteModalVisible(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResultsSubjectLevel;
