import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar'; // Import Navbar
import Login from './pages/Login';
import Homepage from './pages/Homepage';
import Settings from './pages/Settings';
import Sidebar from './components/Sidebar';
import GradeLevelClassDetails from './pages/GradeLevelClassDetails';
import SubjectLevelClassDetails from './pages/SubjectLevelClassDetails';
import Notifications from './pages/Notifications';
import Profile from './pages/Profile';
import SchemeBooks from './pages/SchemeBooks';
import Help from './pages/Help';

const App = () => {
  const location = useLocation();
  const showSidebar = location.pathname !== '/';

  return (
    <AuthProvider>
      <div className="flex flex-col md:flex-row min-h-screen">
        {/* Navbar (Mobile Only) */}
        {showSidebar && (
          <Navbar className="md:hidden fixed top-0 left-0 w-full z-50" />
        )}

        {/* Sidebar (Desktop Only) */}
        {showSidebar && (
          <Sidebar className="hidden md:block w-64 bg-gray-800 text-white fixed top-0 left-0 h-full" />
        )}

        {/* Main Content */}
        <div className={`w-full ${showSidebar ? 'md:ml-64 mt-12 md:mt-0' : ''} `}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Homepage />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/help"
              element={
                <PrivateRoute>
                  <Help />
                </PrivateRoute>
              }
            />
            <Route
              path="/subject-level/:classId"
              element={
                <PrivateRoute>
                  <SubjectLevelClassDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/form-level/:classId"
              element={
                <PrivateRoute>
                  <GradeLevelClassDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/announcements"
              element={
                <PrivateRoute>
                  <Notifications />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/scheme-books"
              element={
                <PrivateRoute>
                  <SchemeBooks />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
