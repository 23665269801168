import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const StudentsSubjectLevel = ({ classId }) => {
  const { user } = useContext(AuthContext);
  const [students, setStudents] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [newStudentRegNumber, setNewStudentRegNumber] = useState('');
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchStudents();
  }, [classId]);

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/subjectlevelclasses-students/get-students-class/${classId}`);
      setStudents(response.data.students);
      setTotalStudents(response.data.total);
    } catch (error) {
      console.error('Error fetching students:', error);
      setErrorMessage(error.response?.data?.message || 'Failed to fetch students');
      setErrorModalVisible(true);
    }
  };

  const handleRemoveStudent = async (RegNumber) => {
    if (window.confirm('Are you sure you want to remove this student?')) {
      try {
        const response = await axios.delete(`${BASE_URL}/subjectlevelclasses-students/remove-student`, {
          data: { classId, RegNumber }
        });
        
        if (response.status === 200) {
          alert(response.data.message);
          fetchStudents();
        }
      } catch (error) {
        console.error('Error removing student:', error);
        setErrorMessage(error.response?.data?.message || 'Failed to remove student');
        setErrorModalVisible(true);
      }
    }
  };

  const handleAddStudent = async (e) => {
    e.preventDefault();
    if (!newStudentRegNumber) {
      setErrorMessage('Please enter a registration number');
      setErrorModalVisible(true);
      return;
    }
    try {
      const response = await axios.post(`${BASE_URL}/subjectlevelclasses-students/add-student`, { 
        classId, 
        RegNumber: newStudentRegNumber 
      });
      
      if (response.status === 201) {
        alert(response.data.message);
        setNewStudentRegNumber('');
        fetchStudents();
      }
    } catch (error) {
      console.error('Error adding student:', error);
      setErrorMessage(error.response?.data?.message || 'Failed to add student');
      setErrorModalVisible(true);
    }
  };

  return (
    <div className="p-4 bg-white">
      <h2 className="text-2xl font-bold mb-4 text-[#11024D]">Students</h2>
      <p className="text-lg mb-4 text-[#11024D]">Total Students: {totalStudents}</p>
      
      <form onSubmit={handleAddStudent} className="mb-4 flex gap-2">
        <input
          className="border border-gray-300 p-2 rounded-md flex-grow"
          placeholder="Enter Student Registration Number"
          value={newStudentRegNumber}
          onChange={(e) => setNewStudentRegNumber(e.target.value)}
        />
        <button
          type="submit"
          className="bg-[#BB005A] text-white px-4 py-2 rounded-md hover:bg-[#a10049]"
        >
          Add Student
        </button>
      </form>

      <div className="overflow-x-auto border border-[#BB005A] rounded-md">
        <table className="min-w-full table-auto">
          <thead className="bg-[#11024D] text-white">
            <tr>
              <th className="p-2 whitespace-nowrap">Reg Number</th>
              <th className="p-2 whitespace-nowrap">Name</th>
              <th className="p-2 whitespace-nowrap">Surname</th>
              <th className="p-2 whitespace-nowrap">Gender</th>
              <th className="p-2 whitespace-nowrap">Action</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr
                key={student.RegNumber}
                className={index % 2 === 0 ? 'bg-[#fff6cc]' : 'bg-white'}
              >
                <td className="p-2 border border-gray-300 whitespace-nowrap">
                  {student.RegNumber}
                </td>
                <td className="p-2 border border-gray-300 whitespace-nowrap">
                  {student.Name}
                </td>
                <td className="p-2 border border-gray-300 whitespace-nowrap">
                  {student.Surname}
                </td>
                <td className="p-2 border border-gray-300 whitespace-nowrap">
                  {student.Gender}
                </td>
                <td className="p-2 border border-gray-300 whitespace-nowrap">
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
                    onClick={() => handleRemoveStudent(student.RegNumber)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Error Modal */}
      {errorModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
            <div className="text-center">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mb-4">
                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Error</h3>
              <p className="text-sm text-gray-500 mb-4">{errorMessage}</p>
              <button
                onClick={() => setErrorModalVisible(false)}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentsSubjectLevel;
