import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from './Api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [employeeNumber, setEmployeeNumber] = useState(localStorage.getItem('employeeNumber') || null);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAuthenticated(true);
    } else {
      delete axios.defaults.headers.common['Authorization'];
      setIsAuthenticated(false);
    }
  }, [token]);

  const login = async (employeeNumber, password) => {
    try {
      const response = await axios.post(`${BASE_URL}/teachers-auth/employee2-auth`, { employeeNumber, password });
      console.log('Login response:', response.data);
      localStorage.setItem('token', response.data.token); 
      localStorage.setItem('employeeNumber', employeeNumber); 
      setToken(response.data.token);
      setEmployeeNumber(employeeNumber); 
      setIsAuthenticated(true);
      console.log('Login successful, token set:', response.data.token);
    } catch (error) {
      console.error('Login error:', error);
      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('An unknown error occurred');
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('employeeNumber'); 
    setToken(null);
    setEmployeeNumber(null); 
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, employeeNumber, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
