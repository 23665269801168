import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const ResultsFormLevel = ({ classId }) => {
  const { user } = useContext(AuthContext);
  const [results, setResults] = useState([]);
  const [term, setTerm] = useState('');
  const [year, setYear] = useState('');
  const [form, setForm] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [subjectResults, setSubjectResults] = useState([]);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [currentGradeLevelResultID, setCurrentGradeLevelResultID] = useState('');
  const [isTermModalVisible, setIsTermModalVisible] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubjects, setLoadingSubjects] = useState(false);

  useEffect(() => {
    if (classId) {
      fetchResults();
    }
  }, [classId]);

  const fetchResults = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}/getallresults`, {
        TermID: term,
        Year: year,
        ClassID: classId,
        form: form,
      });
      if (response.data.length === 0) {
        setNoResults(true);
      } else {
        setResults(response.data);
        console.log(response.data);
        setNoResults(false);
      }
    } catch (error) {
      console.error('Error fetching results:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubjectResults = async (studentRegNumber) => {
    try {
      setLoadingSubjects(true);
      const response = await axios.post(`${BASE_URL}/getSubjectResults`, {
        termID: term,
        year: year,
        classID: classId,
        studentRegNumber: studentRegNumber,
      });
      const fetchedSubjectResults = response.data.data.subjectResults || [];
      console.log('Fetched Subject Results:', fetchedSubjectResults);
      setSubjectResults(fetchedSubjectResults);
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error fetching subject results:', error);
    } finally {
      setLoadingSubjects(false);
    }
  };

  const handleViewSubjectResults = (student) => {
    setSelectedStudent(student);
    fetchSubjectResults(student.RegNumber);
  };

  const handleOpenCommentModal = (currentGradeLevelResultID, comment) => {
    setCurrentComment(comment);
    setCurrentGradeLevelResultID(currentGradeLevelResultID);
    setIsCommentModalVisible(true);
  };

  const handleUpdateComment = async () => {
    try {
      await axios.post(`${BASE_URL}/updateTeacherComment/${currentGradeLevelResultID}`, {
        TeacherComment: currentComment,
      });
      alert('Comment updated successfully');
      fetchResults();
      setIsCommentModalVisible(false);
    } catch (error) {
      console.error('Error updating comment:', error);
      alert('Failed to update comment');
    }
  };

  const handleTermSelect = (selectedTerm) => {
    setTerm(selectedTerm);
    setIsTermModalVisible(false);
  };

  return (
    <div className="p-4 sm:p-6 bg-white">
      <h2 className="text-xl sm:text-3xl font-bold mb-4 text-[#11024D]">Results</h2>
      <div className="flex flex-col sm:flex-row mb-6 space-y-4 sm:space-y-0 sm:space-x-4">
        <button
          className="border border-gray-300 p-2 rounded-md w-full text-[#11024D]"
          onClick={() => setIsTermModalVisible(true)}
        >
          {term ? `Term ${term}` : 'Select Term'}
        </button>
        <input
          type="text"
          className="border border-gray-300 p-2 rounded-md w-full"
          placeholder="Year"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />
        <input
          type="text"
          className="border border-gray-300 p-2 rounded-md w-full"
          placeholder="Form"
          value={form}
          onChange={(e) => setForm(e.target.value)}
        />
      </div>

      <button
        className="bg-[#11024D] text-white p-2 rounded-md w-full hover:bg-[#0E0039] disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={fetchResults}
        disabled={loading}
      >
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2"></div>
            Fetching Results...
          </div>
        ) : (
          'Fetch Results'
        )}
      </button>

      {loading ? (
        <div className="flex items-center justify-center p-8">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#11024D]"></div>
        </div>
      ) : noResults ? (
        <div className="mt-6 text-center text-red-600 font-semibold">
          No results found for the selected term and year.
        </div>
      ) : (
        <div className="overflow-x-auto mt-6 border border-[#11024D] rounded-md">
          <table className="min-w-full text-sm sm:text-base">
            <thead className="bg-[#11024D] text-white">
              <tr>
                <th className="p-2 whitespace-nowrap">Reg Number</th>
                <th className="p-2 whitespace-nowrap">Name</th>
                <th className="p-2 whitespace-nowrap">Surname</th>
                <th className="p-2 whitespace-nowrap">Total Mark</th>
                <th className="p-2 whitespace-nowrap">Class Position</th>
                <th className="p-2 whitespace-nowrap">Teacher Comment</th>
                <th className="p-2 whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={result.RegNumber} className={index % 2 === 0 ? 'bg-[#f4f0ff]' : 'bg-white'}>
                  <td className="p-2 text-center whitespace-nowrap">{result.RegNumber}</td>
                  <td className="p-2 text-center whitespace-nowrap">{result.Name}</td>
                  <td className="p-2 text-center whitespace-nowrap">{result.Surname}</td>
                  <td className="p-2 text-center whitespace-nowrap">{result.TotalMark}</td>
                  <td className="p-2 text-center whitespace-nowrap">{result.ClassPosition}</td>
                  <td className="p-2 text-center whitespace-nowrap">{result.TeacherComment || 'No Comment'}</td>
                  <td className="p-2 text-center whitespace-nowrap space-x-2">
                    <button
                      className="bg-green-500 text-white px-2 py-1 rounded-md hover:bg-green-600"
                      onClick={() => handleOpenCommentModal(result.GradeLevelResultID, result.TeacherComment || '')}
                    >
                      Edit Comment
                    </button>
                    <button
                      className="bg-blue-500 text-white px-2 py-1 rounded-md hover:bg-blue-600"
                      onClick={() => handleViewSubjectResults(result)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Comment Modal */}
      {isCommentModalVisible && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 p-4">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <h3 className="text-xl font-bold mb-4 text-[#11024D]">Edit Teacher Comment</h3>
            <textarea
              className="border border-gray-300 p-2 rounded-md w-full"
              value={currentComment}
              onChange={(e) => setCurrentComment(e.target.value)}
              rows="4"
            />
            <div className="flex justify-end mt-4 space-x-4">
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500"
                onClick={() => setIsCommentModalVisible(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#11024D] text-white px-4 py-2 rounded-md hover:bg-[#0E0039]"
                onClick={handleUpdateComment}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Subject Results Modal */}
      {isModalVisible && selectedStudent && (
  <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 p-4">
    <div className="bg-white p-8 rounded-lg w-full max-w-4xl shadow-lg">
      <div className="text-center mb-6">
        <h3 className="text-2xl font-bold text-[#11024D] mb-2">
          Subject Results for {selectedStudent.Name} {selectedStudent.Surname}
        </h3>
        <p className="text-gray-600 text-sm">
          <span className="font-semibold">Year:</span> {year} &bull; 
          <span className="font-semibold"> Term:</span> {term} &bull; 
          <span className="font-semibold"> Class Position:</span> {selectedStudent.ClassPosition}
        </p>
      </div>

      {loadingSubjects ? (
        <div className="flex items-center justify-center py-16">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-[#11024D]"></div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full border border-gray-200 text-sm sm:text-base">
            <thead className="bg-[#11024D] text-white">
              <tr>
                <th className="p-3 border-b border-gray-300 text-left">Subject Name</th>
                <th className="p-3 border-b border-gray-300 text-left">Mark</th>
                <th className="p-3 border-b border-gray-300 text-left">Grade</th>
              </tr>
            </thead>
            <tbody>
              {subjectResults.length > 0 ? (
                subjectResults.map((result, index) => (
                  <tr
                    key={result.ResultID}
                    className={`${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    } hover:bg-gray-100 transition`}
                  >
                    <td className="p-3 border-b border-gray-300">{result.SubjectName}</td>
                    <td className="p-3 border-b border-gray-300">{result.AverageMark}</td>
                    <td className="p-3 border-b border-gray-300">{result.Grade}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="p-4 text-center text-gray-600">
                    No subject results available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex justify-end mt-6">
        <button
          className="bg-[#11024D] text-white font-medium px-6 py-2 rounded-md hover:bg-[#0E0039] transition"
          onClick={() => setIsModalVisible(false)}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}


      {/* Term Selection Modal */}
      {isTermModalVisible && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 p-4">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <h3 className="text-xl font-bold mb-4 text-[#11024D]">Select Term</h3>
            {[1, 2, 3].map((termOption) => (
              <button
                key={termOption}
                className="w-full p-2 border-b border-gray-300 text-left text-[#11024D] hover:bg-gray-100"
                onClick={() => handleTermSelect(termOption)}
              >
                Term {termOption}
              </button>
            ))}
            <div className="flex justify-end mt-4">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                onClick={() => setIsTermModalVisible(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultsFormLevel;
