import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const Notifications = () => {
  const { user } = useContext(AuthContext);
  const [announcements, setAnnouncements] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const announcementsPerPage = 5;

  useEffect(() => {
    fetchAnnouncements();
  }, [page]);

  const fetchAnnouncements = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/announcements/teacher-announcements`, {
        params: { page, limit: announcementsPerPage },
      });
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
    setLoading(false);
  };

  const openModal = (announcement) => {
    setSelectedAnnouncement(announcement);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedAnnouncement(null);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  return (
    <div className="w-full sm:max-w-5xl mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-6 text-center">
        Teacher Announcements
      </h1>

      {/* Announcements List */}
      <div className="max-h-96 overflow-y-auto">
        {announcements.map((announcement, index) => (
          <div
            key={announcement.AnnouncementID ? announcement.AnnouncementID : index}
            className="border border-gray-200 p-4 mb-4 rounded-lg hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => openModal(announcement)}
          >
            <div className="text-gray-500 text-sm">
              {new Date(announcement.Date).toLocaleDateString()}
            </div>
            <div className="text-gray-900 text-base sm:text-lg font-medium mt-1 break-words">
              {announcement.Description}
            </div>
          </div>
        ))}
        {loading && <div className="text-center text-gray-600">Loading...</div>}
      </div>

      {/* Modal for showing selected announcement details */}
      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 sm:p-6 z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm sm:max-w-md w-full shadow-lg relative">
            {selectedAnnouncement && (
              <div>
                <h2 className="text-xl font-bold mb-4">Announcement Details</h2>
                <div className="text-gray-500 text-sm mb-2">
                  {new Date(selectedAnnouncement.Date).toLocaleDateString()}
                </div>
                <p className="text-gray-800 break-words">{selectedAnnouncement.Description}</p>
                <button
                  className="bg-gray-800 text-white py-2 px-4 rounded-lg mt-4 hover:bg-gray-900"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-6">
        <button
          className={`px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-900 ${
            page === 1 && 'opacity-50 cursor-not-allowed'
          }`}
          disabled={page === 1}
          onClick={handlePreviousPage}
        >
          Previous
        </button>
        <span className="text-gray-900 text-lg font-semibold">{page}</span>
        <button
          className={`px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-900`}
          onClick={handleNextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Notifications;
