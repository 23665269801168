import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const StudentsFormLevel = ({ classId }) => {
  const { user } = useContext(AuthContext);
  const [students, setStudents] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newStudentRegNumber, setNewStudentRegNumber] = useState('');

  useEffect(() => {
    if (classId) {
      fetchStudents();
    }
  }, [classId]);

  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses-students/get-all-students/${classId}`);
      setStudents(response.data.students);
      setTotalStudents(response.data.total);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const handleRemoveStudent = async (regNumber) => {
    if (window.confirm('Are you sure you want to remove the student?')) {
      try {
        await axios.delete(`${BASE_URL}/gradelevelclasses-students/remove-student`, {
          data: { classId, regNumber },
        });
        alert('Student successfully removed from class');
        fetchStudents();
      } catch (error) {
        console.error('Error removing student:', error);
        alert('Failed to remove student');
      }
    }
  };

  const handleAddStudent = async () => {
    if (!newStudentRegNumber) return;
    try {
      await axios.post(`${BASE_URL}/gradelevelclasses-students/add-student`, {
        classId,
        regNumber: newStudentRegNumber,
      });
      alert('Student added successfully');
      setIsModalVisible(false);
      setNewStudentRegNumber('');
      fetchStudents();
    } catch (error) {
      console.error('Error adding student:', error);
      alert('Failed to add student');
    }
  };

  return (
    <div className="p-4 bg-white">
      <h2 className="text-3xl font-bold mb-4 text-[#11024D]">Students</h2>
      <p className="text-lg mb-4 text-[#11024D]">Total Students: {totalStudents}</p>

      <div className="mb-4">
        <button
          className="bg-[#11024D] text-white px-4 py-2 rounded-md hover:bg-[#0E0039]"
          onClick={() => setIsModalVisible(true)}
        >
          Add Student
        </button>
      </div>

      <div className="overflow-auto border border-[#11024D] rounded-md">
        <table className="min-w-full table-auto">
          <thead className="bg-[#11024D] text-white">
            <tr>
              <th className="p-2 border text-start">Reg Number</th>
              <th className="p-2 border text-start">Name</th>
              <th className="p-2 border text-start">Surname</th>
              <th className="p-2 border text-start">Gender</th>
              <th className="p-2 border text-start">Action</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr
                key={student.RegNumber}
                className={`${index % 2 === 0 ? 'bg-[#f4f0ff]' : 'bg-white'}`}
              >
                <td className="p-2 border text-center">{student.RegNumber}</td>
                <td className="p-2 border text-center">{student.Name}</td>
                <td className="p-2 border text-center">{student.Surname}</td>
                <td className="p-2 border text-center">{student.Gender}</td>
                <td className="p-2 border text-center">
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
                    onClick={() => handleRemoveStudent(student.RegNumber)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for adding a new student */}
      {isModalVisible && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg w-full max-w-md">
            <h3 className="text-xl font-bold mb-4 text-[#11024D]">Add Student</h3>
            <input
              className="border border-gray-300 p-2 rounded-md mb-4 w-full"
              placeholder="Enter Student Registration Number"
              value={newStudentRegNumber}
              onChange={(e) => setNewStudentRegNumber(e.target.value)}
            />
            <div className="flex justify-between">
              <button
                className="bg-[#11024D] text-white px-4 py-2 rounded-md hover:bg-[#0E0039]"
                onClick={handleAddStudent}
              >
                Add Student
              </button>
              <button
                className="ml-4 bg-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-400"
                onClick={() => setIsModalVisible(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentsFormLevel;
