import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaHome, FaUser, FaBullhorn, FaBook, FaCog, FaQuestionCircle, FaSignOutAlt } from 'react-icons/fa';
import AuthContext from '../context/AuthContext';
import Logo from '../asssets/logo.jpeg'; // Update with the correct path to your logo

const Navbar = () => {
  const { logout } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {/* Navbar */}
      <div className="fixed md:hidden top-0 left-0 w-full bg-[#11024D] text-white shadow-md z-50 flex items-center justify-between p-4">
        <div className="flex items-center">
          <img src={Logo} alt="Logo" className="w-10 h-10 rounded-md mr-2" />
          <h1 className="text-lg font-bold">SME</h1>
        </div>
        <button
          className="text-white text-2xl md:hidden"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-screen bg-[#11024D] text-white p-4 w-64 z-50 shadow-lg transition-transform ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0`}
      >
        {/* Logo */}
        <div className="flex items-center justify-center mb-8">
          <img src={Logo} alt="Logo" className="w-24 h-auto rounded-md" />
        </div>

        <nav>
          <ul>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaHome className="mr-2" />
              <Link to="/home">Home</Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaUser className="mr-2" />
              <Link to="/profile">Profile</Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaBullhorn className="mr-2" />
              <Link to="/announcements">Announcements</Link>
            </li>
           
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaCog className="mr-2" />
              <Link to="/settings">Settings</Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaQuestionCircle className="mr-2" />
              <Link to="/help">Help</Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaSignOutAlt className="mr-2" />
              <button onClick={logout}>Logout</button>
            </li>
          </ul>
        </nav>
      </div>

      {/* Overlay for mobile menu */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={toggleMenu}
        ></div>
      )}
    </>
  );
};

export default Navbar;
