import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaUser, FaBullhorn, FaBook, FaCog, FaQuestionCircle, FaSignOutAlt } from 'react-icons/fa';
import AuthContext from '../context/AuthContext';
import Logo from '../asssets/logo.jpeg'; // Assuming you have a logo image in your assets folder

const Sidebar = () => {
  const { logout } = useContext(AuthContext);

  return (
    <div>
      {/* Sidebar - Hidden on small to medium screens, visible on larger screens */}
      <div className="fixed top-0 left-0 h-screen bg-[#11024D] text-white p-4 w-64 z-50 shadow-lg hidden md:block">
        {/* Logo at the top */}
        <div className="flex items-center justify-center mb-8">
          <img src={Logo} alt="Logo" className="w-24 h-auto rounded-md" /> {/* Adjust the size as necessary */}
        </div>

        <nav className="mt-10">
          <ul>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaHome className="mr-2" />
              <Link to="/home">Home</Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaUser className="mr-2" />
              <Link to="/profile">Profile</Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaBullhorn className="mr-2" />
              <Link to="/announcements">Announcements</Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaCog className="mr-2" />
              <Link to="/settings">Settings</Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaQuestionCircle className="mr-2" />
              <Link to="/help">Help</Link>
            </li>
            <li className="px-4 py-2 flex items-center hover:bg-pink-800 border-b border-pink-600">
              <FaSignOutAlt className="mr-2" />
              <button onClick={logout}>Logout</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
